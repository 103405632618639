/* eslint-disable @typescript-eslint/ban-types */
import {
  type CurrentSportYearRecord,
  type MenuResponse,
  rdbV1DefaultYearList,
  rdbV1FiltersSportsList,
  type SiteResponse,
  type SiteSubConfigRes,
  type SportFilter,
  v1SitesList,
  v1SitesSubConfigsDetail,
} from '@on3/api';
import { getFulfilledValue, proxyApi } from '@on3/ui-lib/utils/api';
import { getSiteChecks, getSiteMenus } from '@on3/ui-lib/utils/sites';
import { NextComponentType, NextPageContext } from 'next';

import { NextApiParams } from '../types/next';
import { filterRankingSports } from '../utils/filterRankingSports';

const withValidUrl = (
  WrappedComponent: NextComponentType<NextPageContext, unknown, any>,
) =>
  function WithValidUrl(props: any) {
    const currentSite = props?.siteData?.currentSite;

    if (currentSite) {
      return <WrappedComponent {...props} />;
    }

    // Throw error here.
    return null;
  };

interface ISiteObjectResponse {
  currentSite?: SiteResponse;
  siteUrls?: SiteResponse[];
  menuData: MenuResponse[] | null;
  secondaryMenuData: MenuResponse[] | null;
  sportsData?: SportFilter[];
  rankingSports?: SportFilter[];
  defaultYears?: CurrentSportYearRecord | null;
  subConfig?: SiteSubConfigRes | null;
}

withValidUrl.isValid = async (ctx: NextApiParams, siteKey?: number | null) => {
  const siteUrls = await v1SitesList(proxyApi, {});

  // Default to On3
  let currentSite = siteUrls.find((url: SiteResponse) => url.key === 44);

  if (siteKey) {
    currentSite = siteUrls.find((url: SiteResponse) => url.key === siteKey);
  } else {
    if (ctx?.query?.team) {
      currentSite = siteUrls?.find(
        (url: SiteResponse) =>
          url.type === 'Team' &&
          url.slug?.toLowerCase() ===
            (ctx?.query?.team as string)?.toLowerCase(),
      );
    } else if (ctx?.query?.site) {
      currentSite = siteUrls?.find(
        (url: SiteResponse) =>
          url.url?.toLowerCase() ===
          `/sites/${(ctx?.query?.site as string)?.toLowerCase()}`,
      );
    } else if (ctx?.query?.college) {
      currentSite = siteUrls?.find(
        (url: SiteResponse) =>
          url.type === 'Channel' &&
          url.slug?.toLowerCase() ===
            (ctx?.query?.college as string).toLowerCase(),
      );
    }
  }

  if (!currentSite?.key || !currentSite?.live) {
    console.error({ url: ctx?.req?.url, error: 'Valid Url Check: line 62' });

    return null;
  }

  const siteMenus: (Promise<MenuResponse[]> | null)[] = getSiteMenus(
    currentSite,
    ctx,
  );

  const [primaryMenu, secondaryMenu, sportsData, defaultYears, subConfig] =
    await Promise.allSettled([
      siteMenus[0],
      siteMenus[1],
      rdbV1FiltersSportsList(proxyApi),
      rdbV1DefaultYearList(proxyApi),
      v1SitesSubConfigsDetail(proxyApi, currentSite.key),
    ]);

  const sportsDataResponse =
    sportsData.status === 'fulfilled' ? sportsData.value : [];

  const rankingSports = filterRankingSports(sportsDataResponse);

  // Cancer
  const siteChecks = getSiteChecks({
    site: currentSite,
    pathname: ctx.pathname ?? currentSite.url ?? '',
    query: ctx.query ?? {},
  });

  let secondaryMenuData = getFulfilledValue(secondaryMenu);

  if (siteChecks.isTeam || siteChecks.isPro || siteChecks.isOn3) {
    secondaryMenuData = secondaryMenuData?.splice(0, 5) ?? [];
  }
  // End Cancer

  const data: ISiteObjectResponse | null = {
    currentSite,
    siteUrls,
    menuData: getFulfilledValue(primaryMenu),
    secondaryMenuData,
    sportsData: sportsDataResponse,
    rankingSports,
    defaultYears:
      defaultYears.status === 'fulfilled' ? defaultYears.value : null,
    subConfig: getFulfilledValue(subConfig),
  };

  return data;
};

export default withValidUrl;
export { withValidUrl };
